import {Link as RouterLink} from 'react-router-dom';

// material-ui
import {Link} from '@mui/material';

// project imports
import {DASHBOARD_PATH} from 'config';
import Logo from 'ui-component/Logo';
import Logotype from '../../../ui-component/Logotype';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to={DASHBOARD_PATH}>
        <Logotype />
    </Link>
);

export default LogoSection;
