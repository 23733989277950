// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {IconId} from '@tabler/icons';

// constant
const icons = {
    IconId
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const lawyer = {
    id: 'lawyer',
    type: 'group',
    children: [
        {
            id: 'lawyer-profile',
            title: <FormattedMessage id="lawyer-page" defaultMessage="Профиль" />,
            type: 'item',
            url: '/',
            icon: icons.IconId,
            breadcrumbs: false
        },
    ]
};

export default lawyer;
