// third-party
import {createSlice} from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import {dispatch} from '../index';

// ----------------------------------------------------------------------

const initialState = {
    email: '',
    phone: '',
    name: '',
    aboutMe: '',
    city: '',
    experience: '',
    isFetched: false
};

const slice = createSlice({
    name: 'lawyer',
    initialState,
    reducers: {
        setEmail(state, action) {
            state.email = action.payload;
        },
        setPhone(state, action) {
            state.phone = action.payload;
        },
        setName(state, action) {
            state.name = action.payload;
        },
        setAboutMe(state, action) {
            state.aboutMe = action.payload;
        },
        setCity(state, action) {
            state.city = action.payload;
        },
        setExperience(state, action) {
            state.experience = action.payload;
        },
        setIsFetched(state, action) {
            state.isFetched = action.payload;
        },
        setData(state, {payload: {email = '', phone = '', name = '', aboutMe = '', city = '', experience = '',}}) {
            console.log('setData', {email, phone, name, aboutMe, city, experience,});
            state.isFetched = true;
            state.email = email;
            state.phone = phone;
            state.name = name;
            state.aboutMe = aboutMe;
            state.city = city;
            state.experience = experience;
        },
    }
});

export default slice.reducer;

// ----------------------------------------------------------------------

export function fetchData() {

    return async () => {
        try {
            const response = await axios.get('/api/lawyer/data');

            console.log(response.data.data);
            if (response.data.data)
                dispatch(slice.actions.setData(response.data.data));
            else
                dispatch(slice.actions.setIsFetched(true));

        } catch (error) {
            console.log({error});
        }
    };
}
export async function updateData({email, phone, name, aboutMe, city, experience}) {

    console.log({email, phone, name, aboutMe, city, experience});
    // async () => {
        try {
            const response = await axios.post('/api/lawyer/data/update', {email, phone, name, aboutMe, city, experience});

            console.log(response.data);

            dispatch(slice.actions.setData({email, phone, name, aboutMe, city, experience}));

        } catch (error) {
            console.log({error});
        }
    // };
}

export const selectIsEmpty = ({lawyer: {email, phone, name, aboutMe, city, experience,}}) => !email && !phone && !name && !aboutMe && !city && !experience;
export const selectIsFetched = ({lawyer: {isFetched}}) => isFetched;
export const selectEmail = ({lawyer: {email}}) => email;
export const selectPhone = ({lawyer: {phone}}) => phone;
export const selectName = ({lawyer: {name}}) => name;
export const selectAboutMe = ({lawyer: {aboutMe}}) => aboutMe;
export const selectCity = ({lawyer: {city}}) => city;
export const selectExperience = ({lawyer: {experience}}) => experience;
