import PropTypes from 'prop-types';
import {createContext, useEffect, useReducer} from 'react';

// third-party
import {Chance} from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import {LOGIN, LOGOUT, PHONE_CONFIRM} from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {

    console.log({serviceToken});
    if (serviceToken) {
        localStorage.setItem('token', serviceToken);
        // axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('token');
        // delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({children}) => {

    const [state, dispatch] = useReducer(accountReducer, initialState);

    console.log({state});

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('token');

                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    // const response = await axios.get('/api/api/user');
                    const response = await axios.get('/api/user');

                    const payload = {isLoggedIn: true,};

                    if (response.status === 200) {
                        payload.user = response.data;
                    }
                    console.log({response});
                    dispatch({
                        type: LOGIN,
                        payload
                    });
                } else {
                    dispatch({
                        type: LOGOUT,
                        user: null
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT,
                    user: null
                });
            }
        };

        init();
    }, []);

    const login = async (email, password) => {
        const response = await axios.post('/api/account/login', { email, password });
        const { serviceToken, user } = response.data;
        setSession(serviceToken);
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    };

    const phoneRequest = async (phone, userType = null) => {

        const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_SIGN_IN_PHOEN_REQUEST}`, {phone, userType});

        if (response.hasOwnProperty('data') && response.data && response.data.hasOwnProperty('result') && response.data.result === 0) {

            return response.data;
        }
        return null;
    };

    const phoneConfirm = async ({phone, phoneCode, phoneToken}) => {

        const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_SIGN_IN_PHOEN_CONFIRM}`, {phone, phoneCode, phoneToken});
        console.log({response});
        if (response.hasOwnProperty('data') && response.data && response.data.hasOwnProperty('result') && response.data.result === 0) {

            setSession(response.data.token);

            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: response.data.user
                }
            });

            return response.data;
        }
        return null;
    };

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post('/api/account/register', {
            id,
            email,
            password,
            firstName,
            lastName
        });
        let users = response.data;

        if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
            const localUsers = window.localStorage.getItem('users');
            users = [
                ...JSON.parse(localUsers),
                {
                    id,
                    email,
                    password,
                    name: `${firstName} ${lastName}`
                }
            ];
        }

        window.localStorage.setItem('users', JSON.stringify(users));
    };

    const logout = () => {
        setSession(null);
        dispatch({type: LOGOUT});
    };

    const resetPassword = (email) => console.log(email);

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }
    console.log(state.user);
    const isLawyer = () => state.user !== null && state.user !== undefined && state.user.hasOwnProperty('type') && state.user.type === 'LAWYER';
    const isClient = () => state.user !== null && state.user !== undefined && state.user.hasOwnProperty('type') && state.user.type === 'USER';

    return <JWTContext.Provider value={{...state, phoneRequest, phoneConfirm, logout, register, resetPassword, updateProfile, isLawyer, isClient}}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
